body {
  font-family: "Poppins", sans-serif;
  background-color: #3a2658 !important;
}

h2 {
  font-family: "sansationbold", sans-serif;
}

.brandColor {
  color: #dffe77;
}

.gradientBg {
  background: linear-gradient(115deg, #25143b, #6537a1);
}

.gradientBorder {
  border-image: linear-gradient(
      90deg,
      rgba(239, 147, 101, 1),
      rgba(97, 61, 147, 1)
    )
    10;
}

.news a {
  color: #613d93;
}
.news a:hover {
  text-decoration: underline;
}

.news h5 {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
  font-weight: bold;
  margin-bottom: 1rem;
}

.news p {
  margin-bottom: 1rem;
}

.news img {
  width: 50%;
  margin: 0 auto;
  border-radius: 1rem;
}

.news ul {
  list-style: circle !important;
  padding-inline-start: 40px !important;
  margin-bottom: 1rem;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.horizontal-scrolling-items {
  animation-name: marquee;
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
/* Mobile Nav */
.mobile-nav.hidden {
  display: none;
}
.mobile-nav {
  flex-direction: column;
  align-items: start;
  padding-top: 1rem;
  width: 100%;
}
.header-title,
.section-title {
  font-family: "sansationbold", sans-serif;
}
.header-subtitle {
  font-family: "sansationbold", sans-serif;
  color: #dffe77;
}
.background-image {
  background-image: url("./img/bg_with_img.jpg");
  background-size: cover;
  background-position: top -50%;
}
.background-news {
  /* background-image: url('./img/news/header_blog.jpg');
  background-size: 100%;
  background-repeat: no-repeat; */
  background: radial-gradient(
    circle at 50% 0%,
    rgba(97, 61, 147, 1) 0%,
    rgba(29, 21, 44, 1) 49%
  );
}
.background-sun {
  background: radial-gradient(
    circle at 77% 0%,
    rgba(182, 239, 101, 1) 0%,
    rgba(239, 147, 101, 1) 10%,
    rgba(97, 61, 147, 0.5) 20%,
    rgba(37, 20, 59, 0.3) 30%,
    rgba(37, 20, 59, 0) 100%
  );
}
.background-about {
  background: radial-gradient(
    circle at 50% 100%,
    rgba(182, 239, 101, 1) 0%,
    rgba(239, 147, 101, 1) 20%,
    rgba(97, 61, 147, 0.5) 40%,
    rgba(37, 20, 59, 0.3) 60%,
    rgba(37, 20, 59, 0) 100%
  );
}
.background-about-main {
  background: radial-gradient(
    ellipse at 0% 100%,
    rgba(97, 61, 147, 1) 0%,
    rgba(29, 21, 44, 1) 49%
  );
}
.sun-from-bottom {
  background: radial-gradient(
    circle at 50% 100%,
    rgba(182, 239, 101, 1) 0%,
    rgba(239, 147, 101, 1) 30%,
    rgba(97, 61, 147, 0.5) 60%,
    rgba(37, 20, 59, 0.3) 90%,
    rgba(37, 20, 59, 0) 100%
  );
}
@media only screen and (max-width: 768px) {
  .background-sun {
    background: radial-gradient(
      circle at 76% 6%,
      rgba(182, 239, 101, 1) 0%,
      rgba(239, 147, 101, 0.8) 3%,
      rgba(97, 61, 147, 0.4) 7%,
      rgba(37, 20, 59, 0.15) 13%,
      rgba(37, 20, 59, 0) 100%
    );
  }
}
.card-collectibles {
  background-color: #221439;
  background-image: url("./img/for_collectables.png");
  background-size: cover;
  background-position: bottom 50%;
  position: relative;
}
.card-collectibles-cube {
  position: absolute;
  width: 150px;
  height: 150px;
  top: -80px;
  right: -30px;
}

.card-games {
  background-color: #221439;
  background-image: url("./img/for_games.png");
  background-size: cover;
  background-position: bottom 50%;
}

.card-rwa {
  background-color: #221439;
  background-image: url("./img/for_RWA.png");
  background-size: cover;
  background-position: bottom 50%;
  position: relative;
}

.card-rwa-cube {
  position: absolute;
  width: 250px;
  height: 250px;
  bottom: -120px;
  right: -30px;
}
.card-title {
  font-family: "sansationbold", sans-serif;
}
.polkadot {
  background-image: url("./img/parachain_img_bg3.png");
  background-size: contain;
  background-position: bottom;
}
.roadmap-gradient {
  height: 200px;
  background: radial-gradient(
    ellipse at 50% 50%,
    rgba(97, 61, 147, 1) 0%,
    rgba(238, 130, 238, 0) 69%
  );
}
.roadmap-line {
  height: 200px;
  background-image: url("./img/roadmap_line2.png");
  background-size: 95%;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: -5%;
}
.roadmap-gradient-mob {
  height: 400px;
  background: radial-gradient(
    ellipse at 75% 50%,
    rgba(97, 61, 147, 1) 0%,
    rgba(238, 130, 238, 0) 20%
  );
  background-size: 100% 300%;
  background-position: 0% 50%;
}
.roadmap-line-mob {
  height: 400px;
  background-image: url("./img/roadmap_line2_rot.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 75%;
}
.roadmap-1 {
  background-image: url("./img/cube_3_1.png");
  background-position: 0%;
  background-repeat: no-repeat;
}
.roadmap-2 {
  background-image: url("./img/cube_3_2.png");
  background-position: 50%;
  background-repeat: no-repeat;
}
.roadmap-3 {
  background-image: url("./img/cube_3_3.png");
  background-position: 50%;
  background-repeat: no-repeat;
}
.roadmap-4 {
  background-image: url("./img/cube_3_4.png");
  background-position: 100%;
  background-repeat: no-repeat;
}
.roadmap-1-mob {
  background-image: url("./img/cube_3_1.png");
  background-position-x: 76%;
  background-position-y: 20%;
  background-repeat: no-repeat;
}
.roadmap-2-mob {
  background-image: url("./img/cube_3_2.png");
  background-position-x: 80%;
  background-position-y: 30%;
  background-repeat: no-repeat;
}
.roadmap-3-mob {
  background-image: url("./img/cube_3_3.png");
  background-position-x: 80%;
  background-repeat: no-repeat;
}
.roadmap-4-mob {
  background-image: url("./img/cube_3_4.png");
  background-position-x: 86%;
  background-position-y: 200%;
  background-repeat: no-repeat;
}

.border-colors {
  position: relative;
  border: 2px solid #dffe77;
  border-radius: 0.5rem;
  background-clip: padding-box;
}

.border-colors::after {
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background-color: #dffe77;
  background: linear-gradient(to bottom right, #dffe77, #e3976d, #613d93);
  content: "";
  z-index: -1;
  border-radius: 0.5rem;
}

.footer {
  background: radial-gradient(
    circle at 50% 0%,
    rgba(97, 61, 147, 1) 0%,
    rgba(29, 21, 44, 1) 100%
  );
}

.vert-color-border {
  background: linear-gradient(to bottom right, #dffe77, #e3976d, #613d93);
  width: 2px;
  height: auto;
}

.hr-colored {
  background-color: #dffe77; /* For browsers that do not support gradients */
  background: linear-gradient(to right, #dffe77, #e3976d, #613d93);
}

@font-face {
  font-family: "sansationregular";
  src: url("./font/sansation_regular-webfont.woff2") format("woff2"),
    url("./font/sansation_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sansationbold";
  src: url("./font/sansation_bold-webfont.woff2") format("woff2"),
    url("./font/sansation_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sansationitalic";
  src: url("./font/sansation_italic-webfont.woff2") format("woff2"),
    url("./font/sansation_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sansationbold_italic";
  src: url("./font/sansation_bold_italic-webfont.woff2") format("woff2"),
    url("./font/sansation_bold_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
#delete-text {
  display: inline-block;
  vertical-align: middle;
  color: #dffe77;
}

#cursor {
  display: inline-block;
  vertical-align: middle;
  width: 3px;
  height: 50px;
  background-color: #dffe77;
  animation: blink 0.75s step-end infinite;
}

@keyframes blink {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: DFFE77;
  }
}
